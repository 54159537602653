.EmbeddedPlaylists {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    overflow: scroll;
    width: 95%;
    max-height: 950px;
    padding: 2.27rem 1.16rem;
    background-color: rgba(1, 12, 63, 0.7);
    box-shadow: 0 4px 2px 2px #000000;
}

.PlaylistContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: scroll;
    padding: 1.16rem 0;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@media only screen and (max-width: 1020px) {
    .EmbeddedPlaylists {
    width: 90%;
    }
}