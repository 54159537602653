.Delete {
    padding: 0.2rem;
    background-color: #111;
    border-radius: 50%;
    display: flex;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 1.5rem;
    margin: 0 auto;
    z-index: 10;
}

.icon {
    font-size: xx-large;
}